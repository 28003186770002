body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ds-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: white; /* To avoid transparency issues */
  transition: all 0.3s ease; /* Smooth transition for effects */
}

.ds-fixed-header {
  /* Add any specific styles when header is scrolled */
  box-shadow: 0 4px 2px -2px gray; /* Example effect */
}

.ds-banner,
.ds-main-section {
  margin-top: var(--masthead-height);
}
